'use strict'

export default (route, state = {}, root) => {
  var routes = route.slice(1).split('/')
  var content = root.routes[`/${routes[0]}`]

  window.history.pushState(state, null, route)

  root.set(content)
}

'use strict'

import DOM from 'domql'
import Playground from 'domql-playground/src/app'
import * as components from '@symbo.ls/uikit'

import router from './router'

import LOGO_SVG from './logo.svg'

const Main = {
  style: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#1D2043',
    backgroundImage: 'radial-gradient(65% 100%, rgba(0, 0, 0, 0.3) 75%, rgba(0, 0, 0, 0.5) 100%) rgb(29, 32, 67)'
  },
  Img: {
    props: {
      src: LOGO_SVG,
    },
    style: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate3d(-50%, -50%, 0)',
      height: 26
    }
  }
}

DOM.create({
  define: { routes: param => param },

  routes: {
    '/': Main,
    '/playground': Playground
  },

  on: {
    render: element => {
      router(window.location.pathname, {}, element)
    }
  }
}, null, 'app', { components })
